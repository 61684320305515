export function GetUserData(user, password, callBack) {
    if (user.length <= 0 || password.length <= 0) {
        callBack({}, false);
    } else {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: user, password: password })
        };
        fetch("https://transporte.clubmaestranza.com/API/login.php", requestOptions)
            .then(response => {
                response.json().then(data => {
                    if (data) {
                        if (typeof data === 'string') {
                            console.log(data);
                        }else{
                            callBack({
                                id: data.id, userName: user, password: password, user_type: data.user_type
                            }, true);
                        }
                    }
                });
            });
    }
}

export function addNameAndDate(persName, days, minDate, maxDate, callBack) {
    const month = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    var weekName = minDate.getDate() + "/" + month[minDate.getMonth()] + " - " + maxDate.getDate() + "/" + month[maxDate.getMonth()];

    if (persName.length < 1) {
        callBack("Error: Nombre.")
        return;
    }
    if (days.length < 1) {
        callBack("Error: Fecha.")
        return;
    }

    var daysBoolArr = [false, false, false, false, false, false, false];

    days.forEach(day => {
        daysBoolArr[day.getDay()] = true;
    });

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: "padres", password: "j4zeuBa}W@&4gdN", week_name: weekName, person_name: persName, days: daysBoolArr })
    };
    fetch('https://transporte.clubmaestranza.com/API/addNameAndDate.php', requestOptions)
        .then(response => {
            response.json().then(data => {
                if (data) {
                    callBack(data);
                }
            });
        });
}

export function getWeekList(user, password, callBack) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: user, password: password })
    };
    fetch("https://transporte.clubmaestranza.com/API/getWeekList.php", requestOptions)
        .then(response => {
            response.json().then(data => {
                if (data) {
                    callBack(data);
                }
            });
        });
}

export function getWeekData(user, password, weekId, weekName, callBack) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: user, password: password, week_id: weekId })
    };

    fetch("https://transporte.clubmaestranza.com/API/getWeekData.php", requestOptions)
        .then(response => {
            response.json().then(data => {
                if (data) {
                    var $entries = [];
                    data.forEach(element => {
                        $entries.push({
                            id: element.entry_id,
                            personName: element.person_name,
                            mon: parseInt(element.monday),
                            tue: parseInt(element.tuesday),
                            wed: parseInt(element.wednesday),
                            thu: parseInt(element.thursday),
                            fri: parseInt(element.friday),
                            sat: parseInt(element.saturday),
                            sun: parseInt(element.sunday)
                        });
                    });

                    var $week = { weekName: weekName, weekId: weekId, entries: $entries }
                    callBack($week);
                }
            });
        });
}

export function eraseWeek(user, password, weekId, callBack) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: user, password: password, week_id: weekId })
    };

    fetch("https://transporte.clubmaestranza.com/API/eraseWeekData.php", requestOptions)
        .then(response => {
            response.json().then(data => {
                if (data) {
                    callBack(data, weekId);
                }
            });
        });
}