import React, { useState, useEffect } from 'react';
import { UserContext } from './UserContext';
import { LoginPanel } from './LoginPanel';
import { AdminPanel } from './AdminPanel';
import { UserPanel } from './UserPanel';
import { Button, CssBaseline, Box } from '@mui/material';

import './App.css';

function App() {

  const [user, setUser] = useState(null);
  const [openLogin, setOpenLogin] = useState(false);
  const [buttonTxt, setButtonTxt] = useState("");

  useEffect(() => {
    openLogin ? setButtonTxt("Volver") : setButtonTxt("Login");
  }, [openLogin]);

  return (
    <>
      <CssBaseline />
      <div className="App">
        <Box sx={{
          position: "absolute",
          right: "10px",
          top: "10px"
        }}>
          <Button onClick={() => setOpenLogin(!openLogin)}> {buttonTxt} </Button>
        </Box>

        {openLogin ?
          <UserContext.Provider value={{ user, setUser }}>
            {user == null ? <LoginPanel></LoginPanel> : <AdminPanel></AdminPanel>}
          </UserContext.Provider>
          : <UserPanel></UserPanel>
        }
      </div>
    </>
  );
}

export default App;
