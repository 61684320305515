import React, { useState } from 'react';
import LPStyles from "./LoginPanel.module.css";
import { Button, TextField, Grid, Typography } from '@mui/material';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';
import logo from "./logo.svg";
import { addNameAndDate } from './DBHelper';

export function UserPanel() {

    const [personName, setPersonName] = useState("");
    const [selectedDays, setSelectedDays] = useState([]);
    const [errorTxt, setErrorTxt] = useState("");
    const [errorColor, setErrorColor] = useState("green");
    const [buttonDisabled, setButtonDisabled] = useState(false);

    var curr = new Date(); // get current date
    var first = curr.getDate() + 1; // First day is the day of the month - the day of the week
    var last = curr.getDate() - curr.getDay() + 5;// last day is the first day + 6

    //const minDate = new Date(curr.setDate(first));
    const firstWeekDate = addToCurrDate(first - curr.getDay());
    //const minDate = new Date(curr.setDate(first));
    const minDate = addToCurrDate(first);
    //const maxDate = new Date(curr.setDate(last));
    const maxDate = addToCurrDate(last);

    function addToCurrDate(num) {
        var newDate = new Date(curr.toDateString());
        newDate.setDate(num);
        return newDate;
    }

    function handleDayClick(day, { selected, disabled }) {
        if(disabled)
            return;

        const selectedDaysCopy = [...selectedDays];
        if (selectedDays != null) {
            var index = selectedDaysCopy.findIndex(d => d.toDateString() == day.toDateString());

            if (index >= 0) {
                selectedDaysCopy.splice(index, 1);

            } else {
                selectedDaysCopy.push(day);
            }
            setSelectedDays(arr => selectedDaysCopy);
        } else {
            setSelectedDays([day.toDateString()]);
        }
        console.log(day);
        console.log("Disabled: "+ disabled);
        console.log(selected);
        console.log(selectedDays);
    }

    function addNameAndDateCallBack(res) {
        setButtonDisabled(false);
        if (res.includes("Ok")) {
            setErrorTxt("Success!");
            setErrorColor("green");
            setPersonName("");
            setSelectedDays([]);
        } else if (res.includes("Error")) {
            setErrorTxt(res);
            setErrorColor("red");
        }
    }

    const modifiers = {
        today: new Date(),
    };
    const modifiersStyles = {
        today: {
            color: '#ffaaaa'
        }
    };

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <img src={logo} className={LPStyles.LoginLogo} alt="logo" />

                Nombre:
                <TextField type="text" value={personName} onChange={(e) => setPersonName(e.target.value)}></TextField>

                <DayPicker
                    numberOfMonths={first < 1 || last > new Date(maxDate.getFullYear(), maxDate.getMonth(), 0).getDate() ? 2 : 1}
                    month={minDate}
                    showOutsideDays
                    canChangeMonth={true}
                    localeUtils={MomentLocaleUtils} locale="es"
                    disabledDays={[{ daysOfWeek: [0, 4, 6] }, { before: minDate }, { after: maxDate }]}
                    selectedDays={selectedDays}
                    onDayClick={handleDayClick}
                    modifiers={modifiers}
                    modifiersStyles={modifiersStyles}
                />
                <Typography variant="caption" sx={{ color: errorColor }}>{errorTxt}</Typography>
                <Button
                    disabled={buttonDisabled}
                    variant="outlined"
                    onClick={() => { setButtonDisabled(true); addNameAndDate(personName, selectedDays, firstWeekDate, maxDate, addNameAndDateCallBack) }}
                > Enviar </Button>
            </Grid>
        </>
    )
}