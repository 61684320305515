import React, { useState, useContext } from 'react';
import { UserContext } from './UserContext';
import { GetUserData } from './DBHelper';
import LPStyles from "./LoginPanel.module.css";
import { Button, TextField, Grid } from '@mui/material';
import logo from "./logo.svg";

export function LoginPanel() {
    const { setUser } = useContext(UserContext);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    function getUserCallBack(userData, success) {
        if(success)
            setUser(userData);
    }

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >

            <img src={logo} className={LPStyles.LoginLogo} alt="logo" />
            <TextField type="text" value={username} onChange={(e) => setUsername(e.target.value)}></TextField>
            <TextField type="password" value={password} onChange={(e) => setPassword(e.target.value)}></TextField>
            <Button variant="outlined" onClick={() => GetUserData(username, password, getUserCallBack)}> Login </Button>
            </Grid>
        </>
    )
}
