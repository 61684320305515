import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from './UserContext';
import { getWeekData, getWeekList, eraseWeek } from './DBHelper';
import LPStyles from "./LoginPanel.module.css";
import { Button, Grid } from '@mui/material';
import logo from "./logo.svg";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { weekdays } from 'moment';

export function AdminPanel() {
    const { user } = useContext(UserContext);

    var weeksArr = [];
    const [weeks, setWeeks] = useState([]);

    const [latestWeekId, setLatestWeekId] = useState(0);

    useEffect(() => {
        getWeekList(user.userName, user.password, getWeekListCallBack)
    }, [])

    function getWeekListCallBack(weekList) {
        var latestWeekIdHelp = 0;
        weekList.forEach(week => {
            getWeekData(user.userName, user.password, week.week_id, week.week_name, getWeekDataCallBack);
            latestWeekIdHelp = latestWeekIdHelp < parseInt(week.week_id) ? parseInt(week.week_id) : latestWeekIdHelp;
            setLatestWeekId(latestWeekIdHelp)
        });
    }

    function getWeekDataCallBack(weekData) {

        var dayCountArr = [0, 0, 0, 0, 0, 0, 0];
        
        dayCountArr[1] = weekData.entries.filter(e => {
            return e.mon;
        }).length;
        dayCountArr[2] = weekData.entries.filter(e => {
            return e.tue;
        }).length;
        dayCountArr[3] = weekData.entries.filter(e => {
            return e.wed;
        }).length;
        dayCountArr[4] = weekData.entries.filter(e => {
            return e.thu;
        }).length;
        dayCountArr[5] = weekData.entries.filter(e => {
            return e.fri;
        }).length;
        dayCountArr[6] = weekData.entries.filter(e => {
            return e.sat;
        }).length;
        dayCountArr[0] = weekData.entries.filter(e => {
            return e.sun;
        }).length;
        
        weekData.dayCountArr = dayCountArr;
        console.log(weekData);

        if (weeks != null) {
            var index = weeksArr.findIndex(w => w.weekId == weekData.weekId);

            if (index >= 0) {
                weeksArr.splice(index, 1);
            } else {
                weeksArr.push(weekData);
                weeksArr.sort((a, b) => (parseInt(b.weekId) - parseInt(a.weekId)));
            }
            setWeeks(arr => [...weeksArr]);
        } else {
            setWeeks([weekData]);
        }
    }

    function eraseWeekDataCallBack(data, weekId) {
        if (weeks != null) {
            var index = weeksArr.findIndex(w => w.weekId == weekId);

            if (index >= 0) {
                weeksArr.splice(index, 1);

            }
            setWeeks(arr => [...weeksArr]);
        }
    }

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.weekName}
                    </TableCell>
                    <TableCell align="right">
                        {parseInt(row.weekId) != latestWeekId ? <Button
                            onClick={() => eraseWeek(user.userName, user.password, row.weekId, eraseWeekDataCallBack)}
                        >Eliminar</Button> : <></>}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nombre</TableCell>
                                            <TableCell align="center">L: {row.dayCountArr[1]}</TableCell>
                                            <TableCell align="center">M: {row.dayCountArr[2]}</TableCell>
                                            <TableCell align="center">X: {row.dayCountArr[3]}</TableCell>
                                            <TableCell align="center">J: {row.dayCountArr[4]}</TableCell>
                                            <TableCell align="center">V: {row.dayCountArr[5]}</TableCell>
                                            <TableCell align="center">S: {row.dayCountArr[6]}</TableCell>
                                            <TableCell align="center">D: {row.dayCountArr[0]}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.entries.map((entryRow) => (
                                            <TableRow key={entryRow.id}>
                                                <TableCell component="th" scope="row">
                                                    {entryRow.personName}
                                                </TableCell>
                                                {entryRow.mon ? <TableCell sx={{ backgroundColor: "green" }} align="center"> <CheckIcon sx={{ color: "white" }} /> </TableCell> : <TableCell align="center"> <CloseIcon /> </TableCell>}
                                                {entryRow.tue ? <TableCell sx={{ backgroundColor: "green" }} align="center"> <CheckIcon sx={{ color: "white" }} /> </TableCell> : <TableCell align="center"> <CloseIcon /> </TableCell>}
                                                {entryRow.wed ? <TableCell sx={{ backgroundColor: "green" }} align="center"> <CheckIcon sx={{ color: "white" }} /> </TableCell> : <TableCell align="center"> <CloseIcon /> </TableCell>}
                                                {entryRow.thu ? <TableCell sx={{ backgroundColor: "green" }} align="center"> <CheckIcon sx={{ color: "white" }} /> </TableCell> : <TableCell align="center"> <CloseIcon /> </TableCell>}
                                                {entryRow.fri ? <TableCell sx={{ backgroundColor: "green" }} align="center"> <CheckIcon sx={{ color: "white" }} /> </TableCell> : <TableCell align="center"> <CloseIcon /> </TableCell>}
                                                {entryRow.sat ? <TableCell sx={{ backgroundColor: "green" }} align="center"> <CheckIcon sx={{ color: "white" }} /> </TableCell> : <TableCell align="center"> <CloseIcon /> </TableCell>}
                                                {entryRow.sun ? <TableCell sx={{ backgroundColor: "green" }} align="center"> <CheckIcon sx={{ color: "white" }} /> </TableCell> : <TableCell align="center"> <CloseIcon /> </TableCell>}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    /*Row.propTypes = {
        row: PropTypes.shape({
            calories: PropTypes.number.isRequired,
            carbs: PropTypes.number.isRequired,
            fat: PropTypes.number.isRequired,
            history: PropTypes.arrayOf(
                PropTypes.shape({
                    amount: PropTypes.number.isRequired,
                    customerId: PropTypes.string.isRequired,
                    date: PropTypes.string.isRequired,
                }),
            ).isRequired,
            name: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
            protein: PropTypes.number.isRequired,
        }).isRequired,
    };*/

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <img src={logo} className={LPStyles.LoginLogo} alt="logo" />

                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell align="left">Semana</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {weeks.map((row) => (
                                <Row key={row.weekId} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


            </Grid>
        </>
    )
}